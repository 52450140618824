import {
  BikShimmer,
  BodyCaption,
  BodyPrimary,
  BodySecondary,
  Button,
  ButtonGroup,
  ButtonLarge,
  COLORS,
  CheckBox,
  Dropdown,
  DynamicTabs,
  DynamicTabsDetails,
  DynamicTabsStyleDetails,
  Input,
  MEDIA_TYPES,
  Media,
  PostPicker,
  ShimmerImage,
  StyledModal,
  Tag,
  TitleMedium,
  TitleRegular,
  Switch,
} from "@bikdotai/bik-component-library";
import PlusIcon from "../../icons/Plus";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import CrossTagIcon from "../../icons/crossTagIcon";
import { eventsTriggerApiHandler } from "../query-builder/utils";
import { generateUniqueId } from "../../app/action-block/Util";
import DeleteIcon from "../../icons/DeleteIcon";
import {
  Header,
  Container,
  Body,
  ModalTitle,
  CommentAction,
  ActionStrip,
  BtnGrpSelector,
  ImageBar,
  AddButton,
  ExcludeStrip,
  CheckBoxBar,
  VerticalSeparator,
  KeywordContainer,
  CommentReplySection,
  ReplyAddStyle,
  ReplyInputSet,
  AddCommentReply,
  ReplySecionDelete,
  VerticalSeparator2,
  Footer,
  ChipStyle,
  ErrorIndicatorStyle,
  AdDropdown,
} from "./style";
import { ActionEventsList } from "./models";
import AlertTriangle from "../../icons/alertTriangle";
import InstaIdsDropdown from "./instaDropdown";
import RichTextEditor from "../../ui-components/rich-text-editor/RichTextEditor";
import {
  fetchAdAccounts,
  getIgAdsMedia,
} from "../../services/helpers/QueryBuilderHelper";
import { DropdownOption } from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import ErrScreen from "./errScreen";
import PlusIconIG from "../../icons/PlusIconIg";
import styled from "styled-components";
import InstagramIcon from "../../icons/instagramIcon";
import OverlayCrossIcon from "../../icons/OverlayCrossIcon";
import InstagramSkeletonIcon from "../../icons/InstagramSkeletonIcon";
import Modal from "../../ui-components/modal";
import { CloseButtonContainer } from "../../ui-components/modal/Modal.style";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { AIInputs } from "../query-builder/keywords";
import { useVersionJet } from "../../utilities/useVersionJet";
import { isAtleastOneCharacterAlphaNumeric } from "../../app/helper/RegexHelper";

export type AdAccount = {
  name: string;
  id: string;
};

export type AdAccountOption = {
  label: string;
  value: string;
  selected: boolean;
};

export type MediaResponse = {
  adId: string;
  adName: string;
  caption: string;
  creativeId: string;
  id: string;
  media_type: string;
  media_url: string;
  permalink: string;
  products: [];
  timestamp: string;
  iceBreakers?: { value: string; label: string }[];
};

export type MediaResponseParsed = {
  id: string;
  imageUrl: string;
  redirectionUrl: string;
  overlayCaption: string;
  adId?: string;
  mediaType?: "video" | "image";
  timestamp?: string;
  iceBreakers?: { label: string; value: string; selected?: boolean }[];
  adName?: string;
};

type Property = {
  condition: "in" | "contains" | "any" | "random";
  firstOperand:
    | "Comment"
    | "anyPost"
    | "anyAd"
    | "anyReels"
    | "specificPosts"
    | "specificAds"
    | "specificReels"
    | "CommentReplies";
  secondOperand: string[];
  secondOperandType?: "includes" | "excludes";
};

export const formatTimestamp = (date: string) => {
  const inputDate = new Date(date);
  const month = inputDate.getMonth() + 1;
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();
  return `${day}/${month}/${year}`;
};

type Option = {
  label: string;
  value: string;
  selected: boolean;
};

const CommentsConfigScreen = (props: {
  instaId: string;
  storeId: string;
  mediaType: MEDIA_TYPES;
  navigateBack: React.Dispatch<
    React.SetStateAction<ActionEventsList | "default">
  >;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetchedConf: any;
  isDM: boolean;
  onSave: (data: any) => void;
}) => {
  const [anyOrSpecific, setAnyOrSpecific] = useState<string>("Any");
  const [excludeInAction, setExcludeInAction] = useState<boolean>(false);
  const [anyOrSpecificKeywords, setAnyOrSpecificKeywords] =
    useState<string>("Any");
  const [excludeInKeywords, setExcludeInKeywords] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [showPostsModal, setShowPostsModal] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<MediaResponseParsed[]>([]);
  const [allMedia, setAllMedia] = useState<MediaResponseParsed[]>([]);
  const [selectedInsta, setSelectedInsta] = useState<{
    label: string;
    value: string;
    selected: boolean;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [commentReplies, setCommentReplies] = useState<{
    [key: string]: string;
  }>({
    [generateUniqueId()]: "",
  });
  const [showReplySection, setShowReplySection] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [key: string]: boolean;
  }>({
    media: false,
    keywords: false,
  });
  const [textMessage, setTextMessage] = useState<{ [key: string]: string }>({});
  const [adAccountsOptions, setAdAccountsOptions] = useState<AdAccountOption[]>(
    []
  );
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);
  const prevSelectedInsta = useRef<string>("");
  const prevSelectedAd = useRef<string>("");
  const [errReason, setErrReason] = useState<string>("");
  const [allIceBreakers, setAllIceBreakers] = useState<
    { label: string; value: string }[]
  >([]);
  const [loadedTimes, setLoadedTimes] = useState<number>(0);
  const [isAiEnabled, setIsAiEnabled] = useState<boolean>(false);
  const [aiDescription, setAiDescription] = useState<string>("");
  const [aiExample, setAiExample] = useState<string[]>([""]);
  const [aiDescError, setAiDescError] = useState<string>("");
  const [aiExamplesError, setAiExamplesError] = useState<string>("");
  const { isVersionApplicabletoPlan } = useVersionJet();

  useEffect(() => {
    const savedAccountId: string = props?.fetchedConf?.adAccountId || "";
    setIsAiEnabled(props?.fetchedConf?.aiEnabled || false);
    setAiDescription(props?.fetchedConf?.aiDescription || "");
    setAiExample(props?.fetchedConf?.aiExamples || [""]);
    if (props.mediaType === ("ads" as any)) {
      fetchAdAccounts({
        storeId: props.storeId,
      })
        .then((data) => {
          const adAccounts: AdAccountOption[] = [] as AdAccountOption[];
          (data.response || []).forEach((item: AdAccount, idx: number) => {
            adAccounts.push({
              label: item.name,
              value: item.id,
              selected: !savedAccountId
                ? idx == 0
                  ? true
                  : false
                : item.id === savedAccountId
                  ? true
                  : false,
            });
          });
          if (!adAccounts.length) {
            setErrReason("Please connect an ad account and try again");
            setShowErrorScreen(true);
          }
          setAdAccountsOptions(adAccounts);
        })
        .catch();
    }

    if (Object.keys(props.fetchedConf).length) {
      setSelectedMedia(props.fetchedConf.media);
      props.fetchedConf.trigger.eventProperties.forEach((item: any) => {
        if ((item.firstOperand as string).startsWith("any")) {
          setAnyOrSpecific("Any");
          if (item.secondOperand.length) {
            setExcludeInAction(true);
          }
        } else if ((item.firstOperand as string).startsWith("specific")) {
          setAnyOrSpecific("Specific");
        } else if (item.firstOperand === "Comment") {
          if (
            ["contains", "endsWith", "startsWith", "is"].includes(
              item.condition
            )
          ) {
            setAnyOrSpecificKeywords("Specific");
            setKeywords(item.secondOperand);
          } else {
            setAnyOrSpecificKeywords("Any");
            if (item.secondOperand.length) {
              setKeywords(item.secondOperand);
              setExcludeInKeywords(true);
            }
          }
        }
      });
      const res: { [key: string]: string } = {};
      props.fetchedConf.commentReplies.forEach((i: string, idx: number) => {
        res[generateUniqueId() + idx] = i;
      });
      setCommentReplies((prev) => {
        return res;
      });
      setTextMessage(() => {
        return res;
      });
      setShowReplySection(true);
    }
  }, []);

  useEffect(() => {
    if (selectedMedia.length && errors["media"]) {
      setErrors((prev) => {
        return {
          ...prev,
          ["media"]: false,
        };
      });
    }
  }, [selectedMedia]);

  useEffect(() => {
    const selectedAccs = adAccountsOptions.filter((item) => item.selected);
    if (!selectedAccs.length) {
      return;
    }
    if (!prevSelectedAd.current) {
      prevSelectedAd.current = selectedAccs[0].value;
      return;
    }
    if (prevSelectedAd.current != selectedAccs[0].value) {
      prevSelectedAd.current = selectedAccs[0].value;
      setSelectedMedia([]);
      setAllIceBreakers([]);
      setAllMedia([]);
      setKeywords([]);
    }
  }, [adAccountsOptions]);

  useEffect(() => {
    if (!selectedInsta?.value) {
      setIsLoading(true);
      return;
    }

    if (isAd()) {
      if (!adAccountsOptions.filter((i) => i.selected).length) {
        setIsLoading(true);
        return;
      }
    }

    if (isAd() && props.isDM) {
      const instaValue = selectedInsta?.value;
      const adAccountValue = adAccountsOptions.filter((i) => i.selected);

      if (instaValue && adAccountValue) {
        const currLoadedTimes = loadedTimes;
        setLoadedTimes((prev) => prev + 1);
        if (props?.fetchedConf?.media?.length && currLoadedTimes < 1) {
          if (props?.fetchedConf?.instaId !== instaValue) {
            setIsLoading(false);
            return;
          }

          if (props?.fetchedConf?.adAccountId !== adAccountValue[0].value) {
            setIsLoading(false);
            return;
          }

          fetchAdsMedia()
            .then((mediaData) => {
              const selectedIBs = props.fetchedConf.iceBreakers;
              const selectedAdIds = selectedIBs.map((i: any) => i.adId);
              const values = selectedIBs.map((i: any) => i.value);
              const selectedMediaIds = props.fetchedConf.media.map(
                (i: any) => i.id
              );
              const validMedia = mediaData.filter((i) =>
                selectedMediaIds.includes(i.id)
              );

              const validIceBreakers = validMedia
                .filter((i) => i.iceBreakers?.length)
                .map((i) => {
                  return {
                    iceBreakers: i.iceBreakers,
                    adId: i.adId,
                    adName: i.adName,
                    mediaId: i.id,
                  };
                });

              const levelledIB: any = [];
              for (const a of validIceBreakers) {
                if (a.iceBreakers) {
                  for (const iB of a.iceBreakers) {
                    if (
                      selectedAdIds.includes(a.adId) &&
                      values.includes(iB.value)
                    ) {
                      levelledIB.push({
                        ...iB,
                        adId: a.adId,
                        adName: a.adName,
                        mediaId: a.mediaId,
                        selected: true,
                      });
                    } else {
                      levelledIB.push({
                        ...iB,
                        adId: a.adId,
                        adName: a.adName,
                        mediaId: a.mediaId,
                      });
                    }
                  }
                }
              }

              setAllIceBreakers(levelledIB);
              setIsLoading(false);
            })
            .catch();
        } else {
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
    }
  }, [selectedInsta, adAccountsOptions]);

  useEffect(() => {
    if (selectedInsta?.value === "ERROR") {
      setShowErrorScreen(true);
      setErrReason("Could not find instagram account");
      return;
    }

    if (!prevSelectedInsta.current || !selectedInsta?.value) {
      prevSelectedInsta.current = selectedInsta?.value || "";
      return;
    }

    if (
      prevSelectedInsta.current &&
      prevSelectedInsta.current !== selectedInsta.value
    ) {
      setKeywords([]);
      setAllMedia([]);
      setSelectedMedia([]);
      setAllIceBreakers([]);
      setCommentReplies({
        [generateUniqueId()]: "",
      });
      setShowReplySection(false);
      setAnyOrSpecific("Any");
      setAnyOrSpecificKeywords("Any");
      setExcludeInAction(false);
      setExcludeInKeywords(false);
      prevSelectedInsta.current = selectedInsta.value;
    }
  }, [selectedInsta]);

  const tabStyleInfo: DynamicTabsStyleDetails = {
    type: "SQUARE",
  };

  const tabsInfo: DynamicTabsDetails[] = [
    {
      id: 0,
      text: "Any",
      action: (tabName: string) => {
        if (tabName !== anyOrSpecific) {
          setAllMedia([]);
          setSelectedMedia([]);
          setAllIceBreakers([]);
          setExcludeInAction(false);
          setErrors({
            media: false,
            keywords: false,
          });
          setKeywords([]);
        }
        setAnyOrSpecific("Any");
      },
    },
    {
      id: 1,
      text: "Specific",
      action: (tabName: string) => {
        if (tabName !== anyOrSpecific) {
          setAllMedia([]);
          setSelectedMedia([]);
          setAllIceBreakers([]);
          setExcludeInAction(false);
          setErrors({
            media: false,
            keywords: false,
          });
          setKeywords([]);
        }
        setAnyOrSpecific("Specific");
      },
    },
  ];

  const tabsInfoKeywords: DynamicTabsDetails[] = [
    {
      id: 0,
      text: "Any",
      action: (tabName: string) => {
        if (tabName !== anyOrSpecificKeywords) {
          setKeywords([]);
          setExcludeInKeywords(false);
          setErrors({
            media: false,
            keywords: false,
          });
        }
        setAnyOrSpecificKeywords("Any");
      },
    },
    {
      id: 1,
      text: "Specific",
      action: (tabName: string) => {
        if (tabName !== anyOrSpecificKeywords) {
          setKeywords([]);
          setExcludeInKeywords(false);
          setErrors({
            media: false,
            keywords: false,
          });
        }
        setAnyOrSpecificKeywords("Specific");
      },
    },
  ];

  const isAd = () => {
    return props.mediaType === ("ads" as any) ? true : false;
  };

  const checkForAiInputErrors = () => {
    if (aiDescription === "") {
      setAiDescError("Please enter a description");
    } else {
      setAiDescError("");
    }
    if (aiExample.filter((item: string) => item !== "").length === 0) {
      setAiExamplesError("Please enter at least one example");
    } else {
      setAiExamplesError("");
    }
    return (
      aiDescription !== "" &&
      aiExample.filter((item: string) => item !== "").length > 0
    );
  };

  const isPost = () => props.mediaType === MEDIA_TYPES.POST;

  const generateTriggerConf = () => {
    if (isAiEnabled && !checkForAiInputErrors()) {
      return;
    }
    const err: {
      [key: string]: boolean;
    } = {};
    if (anyOrSpecific === "Specific" && !selectedMedia.length) {
      err["media"] = true;
    } else {
      err["media"] = false;
    }

    if (
      anyOrSpecificKeywords === "Specific" &&
      (!keywords.length || !aiExample.length)
    ) {
      err["keywords"] = true;
      if (
        isAd() &&
        props.isDM &&
        allIceBreakers.filter((i: any) => i.selected).length
      ) {
        err["keywords"] = false;
      }
    } else {
      err["keywords"] = false;
    }

    if (Object.values(err).filter((item) => item === true).length) {
      setErrors(err);
      return;
    } else {
      setErrors(err);
    }

    let firstProperty: Property = {} as Property;
    let secondProperty: Property = {} as Property;

    if (anyOrSpecific === "Any") {
      firstProperty = {
        condition: "in",
        firstOperand:
          props.mediaType === ("ads" as any)
            ? "anyAd"
            : props.mediaType === MEDIA_TYPES.POST
              ? "anyPost"
              : "anyReels",
        secondOperand: excludeInAction
          ? selectedMedia.map((item) => {
              if (isAd() && item.adId) {
                if (props.isDM) {
                  return item.id;
                }
                return item.adId;
              }
              return item.id;
            })
          : [],
        secondOperandType: excludeInAction ? "excludes" : "includes",
      };
    } else {
      firstProperty = {
        condition: "in",
        firstOperand:
          props.mediaType === ("ads" as any)
            ? "specificAds"
            : props.mediaType === MEDIA_TYPES.POST
              ? "specificPosts"
              : "specificReels",
        secondOperand: selectedMedia.map((item) => {
          if (isAd() && item.adId) {
            if (props.isDM) {
              return item.id;
            }
            return item.adId;
          }
          return item.id;
        }),
        secondOperandType: "includes",
      };
    }

    if (anyOrSpecificKeywords === "Any") {
      secondProperty = {
        condition: "any",
        firstOperand: "Comment",
        secondOperand: excludeInKeywords ? keywords : [],
        secondOperandType: excludeInKeywords ? "excludes" : "includes",
      };
    } else {
      secondProperty = {
        condition: "contains",
        firstOperand: "Comment",
        secondOperand: keywords,
        secondOperandType: "includes",
      };
    }

    function generateTag() {
      let isAny = anyOrSpecific === "Any";
      let tag =
        props.mediaType === MEDIA_TYPES.POST
          ? isAny
            ? "Any Post"
            : `${selectedMedia.length} posts`
          : isAny
            ? "Any Reels"
            : `${selectedMedia.length} reels`;
      return tag;
    }

    function generateTagAds() {
      let isAny = anyOrSpecific === "Any";
      let tag = isAny ? "Any Ad" : `${selectedMedia.length} Ads`;
      return tag;
    }

    const eventProperties = [firstProperty, secondProperty];
    const eventSelected = "Comments";
    const eventConnector = "AND";
    const mediaType = isAd() ? "ads" : isPost() ? "post" : "reel";
    const media = selectedMedia;
    const replies = Object.values(commentReplies).filter(
      (text) => text.trim().length
    );

    const header = props.isDM
      ? `Direct message on ${props.mediaType}`
      : `Comments on ${props.mediaType}`;
    let subHeader =
      anyOrSpecificKeywords === "Any"
        ? `${props.isDM ? "Message" : "Comment"} contains any keyword`
        : `${props.isDM ? "Message" : "Comment"} contains ${keywords.map((item) => `'${item}'`).join(", ")}`;
    if (
      props.isDM &&
      anyOrSpecific === "Specific" &&
      anyOrSpecificKeywords === "Specific"
    ) {
      if (!keywords.length) {
        subHeader += `${allIceBreakers
          .filter((i: any) => i.selected)
          .map((i) => `'${i.label}'`)
          .join(", ")}`;
      } else {
        subHeader += `, ${allIceBreakers
          .filter((i: any) => i.selected)
          .map((i) => `'${i.label}'`)
          .join(", ")}`;
      }
    }
    const id = props?.fetchedConf.id ? props.fetchedConf.id : uuidv4();
    const type = "ig";
    const tag = isAd() ? generateTagAds() : generateTag();
    const instaId = selectedInsta?.value;
    const operator = "AND";
    const adAccountId = isAd()
      ? adAccountsOptions.filter((item) => item.selected)[0].value
      : null;

    const igType = props.isDM ? `dm_on_${props.mediaType}` : null;
    const iceB =
      props.isDM &&
      isAd() &&
      anyOrSpecific === "Specific" &&
      anyOrSpecificKeywords === "Specific"
        ? allIceBreakers.filter((i: any) => i.selected)
        : [];

    const toSaveConf = {
      trigger: {
        eventProperties,
        eventSelected,
        eventConnector,
      },
      mediaType,
      media,
      header,
      subHeader,
      id,
      type,
      tag,
      instaId,
      operator,
      igType,
      commentReplies: replies,
      adAccountId,
      iceBreakers: iceB,
      aiEnabled: isAiEnabled,
      aiDescription: aiDescription,
      aiExamples: aiExample,
    } as any;

    props.onSave(toSaveConf);

    props.setShowModal(false);
  };

  const onDeleteKeyword = (keyword: string) => {
    setKeywords(keywords.filter((item) => item !== keyword));
  };

  const onSelectKeyword = (keywords: string[]) => {
    setKeywords(keywords);
  };

  const handleSelection = (selection: MediaResponseParsed[]) => {
    setSelectedMedia(selection);
    setShowPostsModal(false);

    if (anyOrSpecific === "Specific") {
      const sIB = selection
        .filter((i) => {
          return (
            i?.iceBreakers?.length &&
            !allIceBreakers.filter((a) => (a as any).adId === i.adId).length
          );
        })
        .map((i) => {
          return {
            iceBreakers: i.iceBreakers,
            adId: i.adId,
            adName: i.adName,
            mediaId: i.id,
          };
        });

      const levelledIB: any = [];
      for (const a of sIB) {
        if (a.iceBreakers) {
          for (const iB of a.iceBreakers) {
            levelledIB.push({
              ...iB,
              selected: false,
              adId: a.adId,
              adName: a.adName,
              mediaId: a.mediaId,
            });
          }
        }
      }

      setAllIceBreakers((prev) => {
        const newCopy = cloneDeep([...prev, ...levelledIB]);
        return newCopy;
      });
    }
  };

  const handlePickerClose = () => {
    setShowPostsModal(false);
  };

  const fetchAdsMedia = async () => {
    if (selectedInsta?.value) {
      return getIgAdsMedia({
        storeId: props.storeId,
        instagramId: selectedInsta.value,
        adAccountId: adAccountsOptions
          .filter((item) => item.selected)[0]
          .value.substring(4),
      })
        .then((data) => {
          const formattedData: MediaResponseParsed[] = [];
          data.response.forEach((item: MediaResponse) => {
            formattedData.push({
              id: item.id,
              imageUrl: item.media_url,
              redirectionUrl: item.permalink,
              overlayCaption: `posted on: ${formatTimestamp(item.timestamp)}`,
              adId: item.adId,
              mediaType: item.media_type === "VIDEO" ? "video" : "image",
              timestamp: item.timestamp,
              iceBreakers: item?.iceBreakers || [],
              adName: item.adName,
            });
          });
          setAllMedia(formattedData);
          return formattedData;
        })
        .catch(() => {
          setAllMedia([]);
          return [];
        });
    }

    return [];
  };

  const fetchMedia = async () => {
    eventsTriggerApiHandler(
      {
        query: {
          propertyName: isPost() ? "specificPosts" : "specificReels",
          triggerName: "Comments",
        },
      },
      props.storeId,
      selectedInsta?.value || props.instaId
    ).then(
      (data: {
        status: number;
        data: {
          caption: string;
          comments_count: number;
          id: string;
          ig_id: string;
          media_product_type: string;
          media_type: string;
          media_url: string;
          permalink: string;
          timestamp: string;
          thumbnail_url: string;
        }[];
      }) => {
        if (data.status === 200 && data.data.length) {
          const formattedData: MediaResponseParsed[] = data.data.map((item) => {
            return {
              id: item.id,
              imageUrl:
                item.media_type === "VIDEO"
                  ? item.thumbnail_url
                  : item.media_url,
              redirectionUrl: item.permalink,
              overlayCaption: `posted on: ${formatTimestamp(item.timestamp)}`,
              mediaType: item.media_type === "VIDEO" ? "video" : "image",
              timestamp: item.timestamp,
            };
          });
          setAllMedia(formattedData);
        }
      }
    );
  };

  const onSelectDropdownOption = (
    option: DropdownOption | DropdownOption[]
  ) => {
    option = option as unknown as AdAccountOption;
    setAdAccountsOptions((prev) => {
      let copy = [...prev];
      copy = copy.map((item) => {
        if (
          item.value === (option as unknown as AdAccountOption).value &&
          (option as unknown as AdAccountOption).selected
        ) {
          return {
            ...item,
            selected: true,
          };
        }

        return {
          ...item,
          selected: false,
        };
      });

      if (!copy.filter((item) => item.selected).length) {
        copy[0].selected = true;
      }

      return copy;
    });
  };

  const onSelectIcrebreakers = (option: DropdownOption | DropdownOption[]) => {
    const adIds = (option as any[]).map((i) => i.adId);
    const values = (option as any[]).map((i) => i.value);

    setAllIceBreakers((prev) => {
      const copy: any = cloneDeep(prev);
      return copy.map((i: any) => {
        if (adIds.includes(i.adId) && values.includes(i.value)) {
          return {
            ...i,
            selected: true,
          };
        } else {
          return {
            ...i,
            selected: false,
          };
        }
      });
    });
  };

  return (
    <Modal
      childrenStyle={{ width: "1032px", height: "560px" }}
      handleClick={(e) => {}}
    >
      <CloseButtonContainer
        onClick={(e) => {
          props.setShowModal(false);
          e.stopPropagation();
        }}
      >
        <OverlayCrossIcon
          height={20}
          width={20}
          color={COLORS.content.secondary}
        />
      </CloseButtonContainer>

      <Container>
        <div style={{ display: "flex" }}>
          <Header style={{ borderBottom: "none", paddingBottom: "4px" }}>
            <TitleMedium>
              {props.isDM
                ? `Direct Message on ${isAd() ? "ads" : isPost() ? "post" : "reel"}`
                : `Comments on ${isAd() ? "ads" : isPost() ? "post" : "reel"}`}
            </TitleMedium>
          </Header>
          <div
            style={{
              marginTop: 15,
              paddingRight: 15,
            }}
          >
            <InstaIdsDropdown
              storeId={props.storeId}
              instaId={props.instaId}
              setSelectedInsta={setSelectedInsta}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px 24px 20px 24px",
            borderBottom: `1px solid ${COLORS.stroke.primary}`,
          }}
        >
          {isVersionApplicabletoPlan("ai-triggers") && (
            <>
              <BodySecondary>Trigger journey using AI instead</BodySecondary>
              <div
                style={{ marginLeft: 12 }}
                onClick={(e) => e.stopPropagation()}
              >
                <Switch
                  value={isAiEnabled}
                  type="brand"
                  onValueChange={(value: any) => {
                    setIsAiEnabled(value);
                  }}
                ></Switch>
              </div>
            </>
          )}
        </div>

        {showErrorScreen ? (
          <Body>
            <div
              style={{
                minHeight: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ErrScreen body={errReason} title="No account found" />
            </div>
          </Body>
        ) : isLoading ? (
          <Body>
            <BikShimmer boxes={[{ width: "968px", height: "350px" }]} />
          </Body>
        ) : (
          <Body>
            {isAd() && !props.isDM ? (
              <AdDropdown onClick={(e) => e.stopPropagation()}>
                <BodySecondary>Ad account</BodySecondary>
                <Dropdown
                  placement="bottom"
                  strategy="fixed"
                  options={adAccountsOptions}
                  size="default"
                  isSearchable={false}
                  isMultiSelect={false}
                  onSelect={(option) => {
                    onSelectDropdownOption(option);
                  }}
                />
              </AdDropdown>
            ) : (
              <></>
            )}

            <ModalTitle>
              <TitleRegular>Journey will be triggered...</TitleRegular>
            </ModalTitle>

            <CommentAction>
              <ActionStrip>
                <BtnGrpSelector>
                  <BodyPrimary>
                    {props.isDM
                      ? "Whenever someone replies on"
                      : "Whenever someone comments on"}
                  </BodyPrimary>
                  <DynamicTabs
                    tabsInfo={tabsInfo}
                    tabStyleInfo={tabStyleInfo}
                    selectedTabId={anyOrSpecific === "Any" ? 0 : 1}
                  />
                  <BodyPrimary>
                    {`${isAd() ? "Ads" : isPost() ? "post" : "reel"}`}
                  </BodyPrimary>
                </BtnGrpSelector>
                {props.isDM && isAd() && anyOrSpecific === "Specific" && (
                  <div
                    style={{
                      marginTop: 12,
                    }}
                  >
                    <AdDropdown onClick={(e) => e.stopPropagation()}>
                      <BodySecondary>Ad account</BodySecondary>
                      <Dropdown
                        placement="bottom"
                        strategy="fixed"
                        options={adAccountsOptions}
                        size="default"
                        isSearchable={false}
                        isMultiSelect={false}
                        onSelect={(option) => {
                          onSelectDropdownOption(option);
                        }}
                      />
                    </AdDropdown>
                  </div>
                )}
                {anyOrSpecific === "Specific" ? (
                  <ImageBar>
                    {
                      <AddButton
                        err={errors["media"]}
                        onClick={async () =>
                          props.mediaType === ("ads" as any)
                            ? fetchAdsMedia()
                                .then(() => setShowPostsModal(true))
                                .catch()
                            : fetchMedia()
                                .then(() => setShowPostsModal(true))
                                .catch()
                        }
                      >
                        <PlusIconIG
                          width={24}
                          height={24}
                          color={
                            errors["media"]
                              ? COLORS.content.negative
                              : COLORS.content.secondary
                          }
                        />
                      </AddButton>
                    }
                    {!selectedMedia.length && (
                      <BodyPrimary
                        color={
                          errors["media"]
                            ? COLORS.content.negative
                            : COLORS.content.secondary
                        }
                      >{`Add ${isAd() ? "Ads" : isPost() ? "post" : "reel"}`}</BodyPrimary>
                    )}
                    {selectedMedia.map((item) => {
                      return (
                        <ImageOverlayCard
                          item={item}
                          mediaType={
                            !isAd()
                              ? props.mediaType
                              : item.mediaType === "video"
                                ? MEDIA_TYPES.REEL
                                : MEDIA_TYPES.POST
                          }
                          onDelete={function (dmedia: Media): void {
                            setSelectedMedia((prev) => {
                              const copy = cloneDeep(prev);
                              const filtered = copy.filter(
                                (smedia) => smedia.id !== dmedia.id
                              );
                              return filtered;
                            });
                            setAllIceBreakers((prev) => {
                              const copy: any = cloneDeep(prev);
                              const filtered = copy.filter(
                                (iB: any) => iB.mediaId !== dmedia.id
                              );
                              return filtered;
                            });
                          }}
                        />
                      );
                    })}
                  </ImageBar>
                ) : (
                  <></>
                )}
              </ActionStrip>

              <ExcludeStrip>
                <CheckBoxBar onClick={(e) => e.stopPropagation()}>
                  <CheckBox
                    isChecked={excludeInAction}
                    onValueChange={(checked) => {
                      checked
                        ? setExcludeInAction(true)
                        : setExcludeInAction(false);
                      setSelectedMedia([]);
                      setAllIceBreakers([]);
                    }}
                    isDisabled={anyOrSpecific === "Specific" ? true : false}
                  />
                  <BodySecondary
                    color={
                      anyOrSpecific === "Specific"
                        ? COLORS.content.inactive
                        : COLORS.content.primary
                    }
                  >{`Exclude specific ${isAd() ? "Ads" : isPost() ? "post" : "reel"}`}</BodySecondary>
                </CheckBoxBar>
                {excludeInAction ? (
                  <div>
                    {props.isDM && isAd() && (
                      <div
                        style={{
                          marginTop: 12,
                        }}
                      >
                        <AdDropdown onClick={(e) => e.stopPropagation()}>
                          <BodySecondary>Ad account</BodySecondary>
                          <Dropdown
                            placement="bottom"
                            strategy="fixed"
                            options={adAccountsOptions}
                            size="default"
                            isSearchable={false}
                            isMultiSelect={false}
                            onSelect={(option) => {
                              onSelectDropdownOption(option);
                            }}
                          />
                        </AdDropdown>
                      </div>
                    )}
                    <ImageBar>
                      {
                        <AddButton
                          onClick={async () =>
                            props.mediaType === ("ads" as any)
                              ? fetchAdsMedia()
                                  .then(() => setShowPostsModal(true))
                                  .catch()
                              : fetchMedia()
                                  .then(() => setShowPostsModal(true))
                                  .catch()
                          }
                        >
                          <PlusIconIG
                            width={24}
                            height={24}
                            color={COLORS.content.secondary}
                          />
                        </AddButton>
                      }
                      {!selectedMedia.length && (
                        <BodyPrimary>{`Add ${isAd() ? "Ads" : isPost() ? "post" : "reel"}`}</BodyPrimary>
                      )}
                      {selectedMedia.map((item) => {
                        return (
                          <ImageOverlayCard
                            item={item}
                            mediaType={
                              !isAd()
                                ? props.mediaType
                                : item.mediaType === "video"
                                  ? MEDIA_TYPES.REEL
                                  : MEDIA_TYPES.POST
                            }
                            onDelete={function (dmedia: Media): void {
                              setSelectedMedia((prev) => {
                                const copy = [...prev];
                                const filtered = copy.filter(
                                  (smedia) => smedia.id !== dmedia.id
                                );
                                return filtered;
                              });
                              setAllIceBreakers([]);
                            }}
                          />
                        );
                      })}
                    </ImageBar>
                  </div>
                ) : (
                  <></>
                )}
              </ExcludeStrip>
            </CommentAction>
            <VerticalSeparator />
            <CommentAction>
              {isAiEnabled ? (
                <div style={{ padding: "16px 16px 32px 16px" }}>
                  <AIInputs
                    aiDescription={aiDescription}
                    setAiDescription={setAiDescription}
                    aiExamples={aiExample}
                    setAiExamples={setAiExample}
                    aiDescError={aiDescError}
                    aiExampleError={aiExamplesError}
                  />
                </div>
              ) : (
                <>
                  <KeywordContainer>
                    <BtnGrpSelector>
                      <BodyPrimary>
                        {props.isDM
                          ? "and reply contains"
                          : "and comment contains"}
                      </BodyPrimary>
                      <DynamicTabs
                        tabsInfo={tabsInfoKeywords}
                        tabStyleInfo={tabStyleInfo}
                        selectedTabId={anyOrSpecificKeywords === "Any" ? 0 : 1}
                      />
                      <BodyPrimary>
                        {props.isDM ? "message" : "keywords"}
                      </BodyPrimary>
                    </BtnGrpSelector>
                    {anyOrSpecificKeywords === "Specific" &&
                      anyOrSpecific === "Specific" &&
                      props.isDM &&
                      isAd() && (
                        <div
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 12,
                            marginTop: 16,
                          }}
                        >
                          <AdDropdown isIcebreaker={true}>
                            <BodySecondary>
                              message contains icebreaker
                            </BodySecondary>
                            <Dropdown
                              options={allIceBreakers as any}
                              size="default"
                              isSearchable={true}
                              placement="bottom"
                              strategy="fixed"
                              isMultiSelect={true}
                              onSelect={(
                                option: DropdownOption[] | DropdownOption
                              ) => {
                                onSelectIcrebreakers(option);
                              }}
                              width="100%"
                              placeHolder="Select any icebreaker"
                            />
                          </AdDropdown>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "16px",
                              flexWrap: "wrap",
                            }}
                          >
                            {((allIceBreakers as any) || []).map((i: any) => {
                              return i.selected ? (
                                <Chip
                                  name={i.label}
                                  onRemove={() => {
                                    setAllIceBreakers((prev: any) => {
                                      const copy = cloneDeep(prev);
                                      return copy.map((c: any) => {
                                        if (
                                          c.adId === i.adId &&
                                          c.value === i.value
                                        ) {
                                          return {
                                            ...c,
                                            selected: false,
                                          };
                                        } else {
                                          return c;
                                        }
                                      });
                                    });
                                  }}
                                />
                              ) : (
                                <></>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {anyOrSpecificKeywords === "Specific" && (
                      <div
                        style={{
                          marginTop: 24,
                        }}
                      >
                        {props.isDM && isAd() && (
                          <BodySecondary
                            color={
                              errors["keywords"] &&
                              !(
                                anyOrSpecific === "Specific" &&
                                anyOrSpecificKeywords === "Specific"
                              )
                                ? COLORS.content.negative
                                : COLORS.content.primary
                            }
                          >
                            message contains keywords
                          </BodySecondary>
                        )}

                        <KeywordsInput
                          keywords={keywords}
                          onDeleteKeyword={onDeleteKeyword}
                          onSelectKeyword={onSelectKeyword}
                          errors={errors}
                          setErrors={setErrors}
                          enableError={
                            !(
                              isAd() &&
                              props.isDM &&
                              anyOrSpecific === "Specific" &&
                              anyOrSpecificKeywords === "Specific"
                            )
                          }
                        />

                        {!keywords.length &&
                          props.isDM &&
                          isAd() &&
                          !errors.keywords &&
                          !(
                            anyOrSpecific === "Specific" &&
                            anyOrSpecificKeywords === "Specific"
                          ) && (
                            <BodyCaption color={COLORS.content.secondary}>
                              Press ENTER to add keyword
                            </BodyCaption>
                          )}

                        {!keywords.length &&
                          props.isDM &&
                          isAd() &&
                          anyOrSpecific === "Specific" &&
                          anyOrSpecificKeywords === "Specific" && (
                            <BodyCaption color={COLORS.content.secondary}>
                              Press ENTER to add keyword
                            </BodyCaption>
                          )}
                      </div>
                    )}

                    {props.isDM &&
                      isAd() &&
                      anyOrSpecific === "Specific" &&
                      anyOrSpecificKeywords === "Specific" &&
                      errors.keywords &&
                      !allIceBreakers.filter((i: any) => i.selected).length && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 8,
                            marginTop: 16,
                          }}
                        >
                          <AlertTriangle
                            width={20}
                            height={20}
                            color={COLORS.content.negative}
                          />
                          <BodySecondary color={COLORS.content.negative}>
                            Please add icebreaker or keyword
                          </BodySecondary>
                        </div>
                      )}
                  </KeywordContainer>

                  <ExcludeStrip>
                    <CheckBoxBar onClick={(e) => e.stopPropagation()}>
                      <CheckBox
                        isChecked={excludeInKeywords}
                        onValueChange={(checked) => {
                          checked
                            ? setExcludeInKeywords(true)
                            : setExcludeInKeywords(false);
                          setKeywords([]);
                        }}
                        isDisabled={
                          anyOrSpecificKeywords === "Specific" ? true : false
                        }
                      />
                      <BodySecondary
                        color={
                          anyOrSpecificKeywords === "Specific"
                            ? COLORS.content.inactive
                            : COLORS.content.primary
                        }
                      >{`Exclude specific keywords`}</BodySecondary>
                    </CheckBoxBar>
                    {excludeInKeywords ? (
                      <KeywordsInput
                        keywords={keywords}
                        onDeleteKeyword={onDeleteKeyword}
                        onSelectKeyword={onSelectKeyword}
                        enableError={true}
                      />
                    ) : (
                      <></>
                    )}
                  </ExcludeStrip>
                </>
              )}
            </CommentAction>
            {(function () {
              if (props.isDM) {
                return false;
              }

              return true;
            })() && <VerticalSeparator />}
            {(function () {
              if (props.isDM) {
                return false;
              }

              return true;
            })() &&
              (showReplySection && Object.keys(commentReplies).length ? (
                <CommentReplySection>
                  <ReplyAddStyle>
                    <BodyPrimary>
                      {
                        "Create multiple replies to send them randomly in reply to Comments."
                      }
                    </BodyPrimary>
                    {Object.entries(commentReplies).map(([key, reply]) => {
                      return (
                        <ReplyInputSet key={key}>
                          <RTEWrapperStyle>
                            <RichTextEditor
                              placeHolder="eg. Thanks for your response"
                              uniqueId={key}
                              textMessage={reply}
                              setTextMessage={setTextMessage}
                              onChange={(text) => {
                                setCommentReplies((prev) => {
                                  return {
                                    ...prev,
                                    [key]: text,
                                  };
                                });
                              }}
                              selectedVariableList={[]}
                              setSelectedVariableList={() => {}}
                              hideWordLimit={true}
                              customHeight="48px"
                              showVariableOnly={true}
                              showEmojiAlso={true}
                              pickerSize={"small"}
                            />
                          </RTEWrapperStyle>

                          <div
                            onClick={(e) => {
                              delete commentReplies[key];
                              if (!Object.keys(commentReplies).length) {
                                setShowReplySection(false);
                                setCommentReplies((prev) => {
                                  return {
                                    ...prev,
                                    [generateUniqueId()]: "",
                                  };
                                });
                              } else {
                                setCommentReplies({ ...commentReplies });
                              }
                              e.stopPropagation();
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "flex-start",
                              paddingTop: 14,
                            }}
                          >
                            <DeleteIcon
                              width={20}
                              height={20}
                              color={COLORS.content.secondary}
                            />
                          </div>
                        </ReplyInputSet>
                      );
                    })}
                    <AddCommentReply
                      onClick={(e) => {
                        setCommentReplies((prev) => {
                          return {
                            ...prev,
                            [generateUniqueId()]: "",
                          };
                        });
                        e.stopPropagation();
                      }}
                    >
                      <PlusIcon color={"#BDBDBD"} />
                      <ButtonLarge color={COLORS.content.secondary}>
                        Add comment reply
                      </ButtonLarge>
                    </AddCommentReply>
                  </ReplyAddStyle>

                  <ReplySecionDelete>
                    <VerticalSeparator2 />

                    <div
                      onClick={(e) => {
                        setShowReplySection(false);
                        setCommentReplies({
                          [generateUniqueId()]: "",
                        });
                        e.stopPropagation();
                      }}
                    >
                      <DeleteIcon
                        width={20}
                        height={20}
                        color={COLORS.content.secondary}
                      />
                    </div>
                  </ReplySecionDelete>
                </CommentReplySection>
              ) : (
                <AddCommentReply
                  onClick={(e) => {
                    setShowReplySection(true);
                    setCommentReplies({
                      [generateUniqueId()]: "",
                    });
                    e.stopPropagation();
                  }}
                >
                  <PlusIcon color={"#BDBDBD"} />
                  <ButtonLarge color={COLORS.content.secondary}>
                    Add comment reply
                  </ButtonLarge>
                </AddCommentReply>
              ))}
          </Body>
        )}
        <Footer>
          {Object.values(errors).filter((item) => item === true).length ? (
            <ErrorIndicator />
          ) : (
            <div></div>
          )}
          {!isLoading && (
            <Button
              disabled={showErrorScreen}
              buttonText={showErrorScreen ? "Close" : "Create"}
              size={"medium"}
              onClick={(e) => {
                e.stopPropagation();
                generateTriggerConf();
              }}
            />
          )}
        </Footer>
      </Container>
      {showPostsModal && (
        <PostPicker
          selected={selectedMedia}
          onSelect={handleSelection}
          title={`Select ${isAd() ? "Ads" : isPost() ? "posts" : "reels"}`}
          showModal={showPostsModal}
          onClose={() => handlePickerClose()}
          media={allMedia}
          showFilter={true}
          mediaType={
            isAd()
              ? ("Ad" as any)
              : isPost()
                ? MEDIA_TYPES.POST
                : MEDIA_TYPES.REEL
          }
        />
      )}
    </Modal>
  );
};

export const Chip = (props: {
  name: string;
  onRemove: (name: string) => void;
  icon?: boolean;
}) => {
  const { icon = true } = props;
  return (
    <ChipStyle>
      <BodyCaption>{props.name}</BodyCaption>
      {icon ? (
        <div
          onClick={(e) => {
            props.onRemove(props.name);
            e.stopPropagation();
          }}
        >
          <CrossTagIcon width={16} height={16} color={"#616161"} />
        </div>
      ) : (
        <></>
      )}
    </ChipStyle>
  );
};

export const KeywordsInput = (props: {
  keywords: string[];
  onDeleteKeyword: (name: string) => void;
  errors?: { [key: string]: boolean };
  setErrors?: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  onSelectKeyword?: (kwds: string[]) => void;
  enableError?: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const {
    keywords,
    onDeleteKeyword,
    errors,
    setErrors,
    onSelectKeyword,
    enableError,
  } = props;

  return (
    <div
      style={{
        marginTop: 12,
      }}
    >
      <Input
        onChangeText={function (input: string, event?: any): void {
          if (
            event &&
            event?.type === "keydown" &&
            (event.which === 13 || event.keyCode === 13)
          ) {
            if (input.trim()) {
              const trimmedValue = input.trim();
              if (!isAtleastOneCharacterAlphaNumeric(trimmedValue)) {
                setErrors &&
                  setErrors((prev) => {
                    return {
                      ...prev,
                      ["keywords"]: true,
                    };
                  });
                return;
              }
              setInputValue("");
              onSelectKeyword && onSelectKeyword([...keywords, input.trim()]);

              if (errors?.["keywords"]) {
                setErrors &&
                  setErrors((prev) => {
                    return {
                      ...prev,
                      ["keywords"]: false,
                    };
                  });
              }
            }
          } else {
            setInputValue(input);
          }
        }}
        value={inputValue}
        width="392px"
        placeholder="Ex. Hello"
        errorMessage={
          errors?.["keywords"] && enableError
            ? "Press ENTER to add keyword. Atleast one alphanumeric character is required"
            : ""
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          marginTop: 8,
          flexWrap: "wrap",
        }}
      >
        {keywords.map((k, idx) => {
          return (
            <Chip
              key={generateUniqueId() + idx + k}
              name={k}
              onRemove={onDeleteKeyword}
            />
          );
        })}
      </div>
    </div>
  );
};

export const ErrorIndicator = () => {
  return (
    <ErrorIndicatorStyle>
      <AlertTriangle color={COLORS.content.negative} width={20} height={20} />
      <BodySecondary color={COLORS.content.negative}>
        Some inputs are missing. Please check
      </BodySecondary>
    </ErrorIndicatorStyle>
  );
};

export default CommentsConfigScreen;

const ImageOverlayCard = (props: {
  item: Media;
  mediaType: MEDIA_TYPES;
  onDelete: (item: Media) => void;
}) => {
  const { item } = props;
  return (
    <div key={item.id} style={{ padding: "7px" }}>
      <ProductCard mediaType={props.mediaType}>
        <ShimmerImage
          imageUrl={item.imageUrl}
          borderRadius={4}
          width={props.mediaType === MEDIA_TYPES.POST ? 100 : 70}
          height={100}
          alt={item.overlayCaption}
        />

        <div className="overlay">
          <a target="_blank" rel="noreferrer" href={item.redirectionUrl}>
            <div className="overlay__redirection">
              {props.mediaType === MEDIA_TYPES.POST && (
                <InstagramSkeletonIcon height={22} width={22} />
              )}
              <BodyPrimary
                color={COLORS.background.brand}
                style={{
                  fontWeight: 500,
                }}
              >
                View
              </BodyPrimary>
            </div>
          </a>
          <div className="overlay__caption">
            <BodyCaption color={COLORS.content.placeholder}>
              {item.overlayCaption}
            </BodyCaption>
          </div>
        </div>
        <button
          className="cross-icon"
          onClick={(e) => {
            props.onDelete(item);
            e.stopPropagation();
          }}
        >
          <OverlayCrossIcon
            width={12}
            height={12}
            color={COLORS.content.secondary}
          />
        </button>
      </ProductCard>
    </div>
  );
};

const RTEWrapperStyle = styled.div`
  max-width: 392px;
  min-width: 392px;
  .tool__box {
    position: relative;
    right: 0;
  }

  .hide__toolbar {
    display: none;
    position: relative;
    top: -1000px;
    right: 0px;
  }

  .EmojiPickerReact {
    margin-top: 0px;
  }
`;

const ProductCard = styled.div<{ mediaType: MEDIA_TYPES }>`
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.primary};
  position: relative;
  height: 102px;

  .overlay {
    display: none;
  }

  .overlay__caption {
    text-align: center;
    background: #fafafa;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .overlay__redirection {
    display: flex;
    justify-content: center;
    gap: 5px;
    padding: 10px;
  }

  img {
    border-radius: 4px;
    object-fit: cover;
    width: ${(props) => (props.mediaType === MEDIA_TYPES.POST ? 100 : 70)}px;
    height: ${(props) => (props.mediaType === MEDIA_TYPES.POST ? 100 : 100)}px;
  }
  .cross-icon {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid ${COLORS.stroke.primary};
    background-color: ${COLORS.surface.subdued};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
  }

  &:hover {
    .overlay {
      top: 0;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: ${(props) => (props.mediaType === MEDIA_TYPES.POST ? 100 : 70)}px;
      height: ${(props) =>
        props.mediaType === MEDIA_TYPES.POST ? 100 : 100}px;
      a {
        text-decoration: none;
      }
    }
  }
`;
