import { channel } from "../messageBlock/Constants";

const ALPHANUMERIC_REG = /[a-zA-Z0-9]/;

export const regexp = /(?<=\{\{)([^\}]+)(?=\}\})/g;
export const htmlToWhatsAppTextConverter = (
  editorText: string,
  channelType?: string | undefined
) => {
  let resultText = JSON.stringify(editorText || "");

  switch (channelType) {
    case channel.INSTAGRAM:
      resultText = resultText.replaceAll("<em>", "");
      resultText = resultText.replaceAll("</em>", "");
      resultText = resultText.replaceAll("<strong>", "");
      resultText = resultText.replaceAll("</strong>", "");
      resultText = resultText.replaceAll("&nbsp;", "");
      break;
    default:
      resultText = resultText.replaceAll("<em>", "_");
      resultText = resultText.replaceAll("</em>", "_");
      resultText = resultText.replaceAll("<strong>", "*");
      resultText = resultText.replaceAll("</strong>", "*");
      resultText = resultText.replaceAll("&nbsp;", "");
      break;
  }

  resultText = resultText.replace(new RegExp("<[^>]*>", "g"), "");
  let elementList = resultText.split("");
  elementList.pop();
  elementList.shift();
  resultText = elementList.join("");

  return resultText;
};

export const isAtleastOneCharacterAlphaNumeric = (text: string) => {
  return ALPHANUMERIC_REG.test(text);
};
