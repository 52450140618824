import { COLORS } from "@bikdotai/bik-component-library";
import { useAppSelector } from "../../../state/store";

export const NodeIdHeader = (props: { nodeId: string }) => {
  const homeState = useAppSelector((state) => state.homeState);

  const isSSO = homeState.userData?.loginType === "sso";

  if (!isSSO) {
    return <></>;
  }

  return (
    <span
      style={{ marginLeft: "4px", color: COLORS.content.secondary }}
      className="customNode__header__title"
    >
      {props.nodeId}
    </span>
  );
};
