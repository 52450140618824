import { ErrorsModalStyle } from "./errorsModal.style";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { WarnIcon } from "../../icons/warnIcon";
import { useAppSelector } from "../../state/store";
import { errorDataActions } from "../../state/errorDataState";
import {
  BodyCaption,
  COLORS,
  StyledModal,
} from "@bikdotai/bik-component-library";
import { homeDataAction } from "../../state/homeState";

interface Props {
  onSave: () => void;
  onPublish: (forcePublishFromBackend?: boolean) => void;
}

interface FlowDetails {
  flow_id: string;
  flow_name: string;
  channel: string;
}

interface CardProps {
  header: string;
  body: string;
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  icon?: any;
  flowDetails?: FlowDetails[];
}

const ErrorCard: FunctionComponent<CardProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        borderRadius: 4,
        borderLeft: `4px solid ${props.borderColor}`,
        marginBottom: 8,
      }}
    >
      <div
        style={{ padding: "8px 16px 0", display: "flex", textAlign: "center" }}
      >
        {React.cloneElement(props.icon)}
        <div
          className="body-caption"
          style={{ marginLeft: 8, color: props.textColor }}
        >
          {props.header}
        </div>
      </div>
      <div
        style={{ padding: "4px 24px 8px 40px", color: "#212121" }}
        className="body-caption"
      >
        {props.body}
      </div>
      {props?.flowDetails && (
        <div
          style={{ paddingLeft: "40px", paddingBottom: "8px", marginTop: -8 }}
        >
          {props.flowDetails.map((item, i) => (
            <>
              <a
                href={`../../${item.flow_id}/${item.channel}/edit`}
                target="_blank"
                rel={"noreferrer"}
              >
                {item.flow_name}
              </a>
              {i !== props.flowDetails!.length - 1 && (
                <span style={{ color: COLORS.text.brand }}>{", "}</span>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

const ErrorsModal: FunctionComponent<Props> = (props) => {
  const dispatcher = useDispatch();
  const { colors } = useTheme();
  const errorState = useAppSelector((state) => state.errorDataState.errors);
  const errorStateComplete = useAppSelector((state) => state.errorDataState);
  const selectedNode = useAppSelector(
    (state) => state.errorDataState.selectedNode
  );
  const store = useAppSelector((state: any) => state);
  const currentFlow = useAppSelector((state) => state.homeState.flowId);
  const [nodeWarningsCount, setNodeWarningsCount] = useState(0);
  const [nodeErrorsCount, setNodeErrorsCount] = useState(0);
  const nodeFlow = useAppSelector((state) => state.storeState.flow);
  const [isChildFlow, setIsChildFlow] = useState(false);
  const [childFlowErrors, setChildFlowErrors] = useState<{
    flowDetails: FlowDetails[];
    errors: string[];
  }>();
  const [childFlowWarnings, setChildFlowWarnings] = useState<{
    flowDetails: FlowDetails[];
    warnings: string[];
  }>();
  const [isPublishAnywayLoading, setIsPublishAnywayLoading] = useState(false);

  const closeModal = () => {
    dispatcher(
      errorDataActions.updateErrorState({
        ...errorStateComplete,
        errorModalOpenStatus: false,
        selectedNode: "",
      })
    );
    dispatcher(
      homeDataAction.addState({
        ...store.homeState,
        fromTestModeToggle: false,
      })
    );
    setChildFlowWarnings({ flowDetails: [], warnings: [] });
    setChildFlowErrors({ flowDetails: [], errors: [] });
    setIsChildFlow(false);
  };

  const GlobalError = (props: any) => {
    return (
      <>
        {Object.keys(props.errors).map((key: string) => {
          return (
            <>
              <BodyCaption style={{ fontWeight: "bold" }}>
                {props.errors[key].node_names.join(", ")}
              </BodyCaption>
              <ErrorCard
                backgroundColor={colors.background.negative.light}
                textColor={colors.content.negative}
                header={"Error"}
                body={props.errors[key].error}
                borderColor={colors.stroke.negative.vibrant}
                icon={React.cloneElement(<WarnIcon />, {
                  color: colors.content.negative,
                  width: 16,
                  height: 16,
                })}
              />
            </>
          );
        })}
      </>
    );
  };

  const GlobalWarning = (props: any) => {
    return (
      <>
        {Object.keys(props.warnings).map((key: string) => {
          return (
            <>
              <BodyCaption style={{ fontWeight: "bold" }}>
                {props.warnings[key].node_names.join(", ")}
              </BodyCaption>
              <ErrorCard
                backgroundColor={colors.background.warning.light}
                textColor={colors.content.warning}
                header={"Warning"}
                body={props.warnings[key].warning}
                borderColor={colors.content.warning}
                icon={React.cloneElement(<WarnIcon />, {
                  color: colors.warning,
                  width: 16,
                  height: 16,
                })}
              />
            </>
          );
        })}
      </>
    );
  };

  const renderErrors = (
    error: string,
    flowDetails: FlowDetails[] | undefined
  ) => {
    return (
      <ErrorCard
        flowDetails={flowDetails}
        backgroundColor={colors.background.negative.light}
        textColor={colors.content.negative}
        header={"Error"}
        body={error}
        borderColor={colors.stroke.negative.vibrant}
        icon={React.cloneElement(<WarnIcon />, {
          color: colors.content.negative,
          width: 16,
          height: 16,
        })}
      />
    );
  };

  const renderWarnings = (
    warning: string,
    flowDetails: FlowDetails[] | undefined
  ) => {
    return (
      <ErrorCard
        flowDetails={flowDetails}
        backgroundColor={colors.background.warning.light}
        textColor={colors.content.warning}
        header={"Warning"}
        body={warning}
        borderColor={colors.content.warning}
        icon={React.cloneElement(<WarnIcon />, {
          color: colors.warning,
          width: 16,
          height: 16,
        })}
      />
    );
  };

  const renderGlobalChildReport = (childReport: any, isWarning: boolean) => {
    if (
      typeof childReport === "undefined" ||
      Object.keys(childReport).length === 0
    )
      return null;
    const flowDetails: any = [];
    const nodeNames: string[] = [];
    let issueExists = false;
    Object.keys(childReport).map((flowId: string) => {
      const issues = isWarning
        ? childReport[flowId]?.combined_warnings
        : (childReport[flowId]?.combined_errors ?? {});
      const isIssue =
        typeof issues !== "undefined" && Object.keys(issues).length > 0;
      if (isIssue) {
        issueExists = true;
        flowDetails.push(childReport[flowId].flow_details);
        nodeNames.push(childReport[flowId].flow_details.parent_node_name);
      }
    });
    if (!issueExists) return null;
    if (isWarning) {
      return (
        <>
          {nodeNames.length && (
            <BodyCaption style={{ fontWeight: "bold" }}>
              {nodeNames.join(", ")}
            </BodyCaption>
          )}
          {renderWarnings(
            "There are some warnings that need your attention while starting another flow",
            flowDetails
          )}
        </>
      );
    } else {
      return (
        <>
          {nodeNames.length && (
            <BodyCaption style={{ fontWeight: "bold" }}>
              {nodeNames.join(", ")}
            </BodyCaption>
          )}
          {renderErrors(
            "There are some errors that need your attention while starting another flow",
            flowDetails
          )}
        </>
      );
    }
  };

  useEffect(() => {
    if (selectedNode) {
      const nodeData = nodeFlow[selectedNode];
      if (
        nodeData &&
        nodeData["type"] === "action" &&
        nodeData["actions"]["start_flow"] &&
        errorState[currentFlow]["child_reports"]
      ) {
        const startFlowId = nodeData.actions.start_flow.flow_id;
        const nodeErrorState =
          errorState[currentFlow]["child_reports"][`${startFlowId}`];
        const flowDetails = nodeErrorState?.flow_details;
        let errors = 0;
        let warnings = 0;
        let _childFlowErrors: { flowDetails: FlowDetails[]; errors: string[] } =
          { flowDetails: [], errors: [] };
        let _childFlowWarnings: {
          flowDetails: FlowDetails[];
          warnings: string[];
        } = { flowDetails: [], warnings: [] };
        if (nodeErrorState) {
          Object.keys(nodeErrorState).forEach((nodeId) => {
            const nodeLevelData = nodeErrorState[nodeId];
            if (
              nodeLevelData &&
              nodeLevelData.errors &&
              nodeLevelData.errors.length
            ) {
              _childFlowErrors = {
                flowDetails: flowDetails ? [flowDetails] : [],
                errors: [..._childFlowErrors.errors, nodeLevelData.errors],
              };
              errors = errors + nodeLevelData.errors.length;
            }
            if (
              nodeLevelData &&
              nodeLevelData.warnings &&
              nodeLevelData.warnings.length
            ) {
              _childFlowWarnings = {
                flowDetails: flowDetails ? [flowDetails] : [],
                warnings: [
                  ..._childFlowWarnings.warnings,
                  nodeLevelData.warnings,
                ],
              };
              warnings = warnings + nodeLevelData.warnings.length;
            }
          });
        }
        if (errorState[currentFlow][selectedNode]?.warnings.length) {
          _childFlowWarnings = {
            ..._childFlowWarnings,
            warnings: [
              ..._childFlowWarnings.warnings,
              errorState[currentFlow][selectedNode].warnings,
            ],
          };
          warnings =
            warnings + errorState[currentFlow][selectedNode].errors.length;
        }
        if (errorState[currentFlow][selectedNode]?.errors.length) {
          _childFlowErrors = {
            ..._childFlowErrors,
            errors: [
              ..._childFlowErrors.errors,
              errorState[currentFlow][selectedNode].errors,
            ],
          };
          errors = errors + errorState[currentFlow][selectedNode].errors.length;
        }
        setChildFlowErrors(_childFlowErrors);
        setChildFlowWarnings(_childFlowWarnings);
        setNodeErrorsCount(errors);
        setNodeWarningsCount(warnings);
        setIsChildFlow(true);
      } else {
        if (errorState[currentFlow][selectedNode]?.warnings?.length) {
          setNodeWarningsCount(
            errorState[currentFlow][selectedNode].warnings.length
          );
        }
        if (errorState[currentFlow][selectedNode]?.errors?.length) {
          setNodeErrorsCount(
            errorState[currentFlow][selectedNode].errors.length
          );
        }
      }
    }
  }, [selectedNode]);

  useEffect(() => {
    setIsPublishAnywayLoading(false);
  }, [errorStateComplete]);

  return (
    <StyledModal
      open
      headingTitle="Please Review"
      onClose={closeModal}
      centralContainerStyles={{ width: 470 }}
      primaryButton={{
        onClick: () => closeModal(),
        buttonText: "Okay",
      }}
      secondaryButton={
        errorState[currentFlow]["can_force_publish"]
          ? {
              onClick: () => {
                setIsPublishAnywayLoading(true);
                props.onPublish(true);
              },
              buttonText: "Publish Anyway",
              isLoading: isPublishAnywayLoading,
              buttonType: "secondary",
            }
          : undefined
      }
    >
      <ErrorsModalStyle>
        <div
          style={{ overflow: "auto", height: "fit-content", maxHeight: "70vh" }}
        >
          {((!selectedNode && errorStateComplete.errorsCount > 0) ||
            (selectedNode && nodeErrorsCount > 0)) && (
            <>
              <div className="title-regular">Errors</div>
              <div
                className="body-secondary"
                style={{
                  marginTop: 4,
                  marginBottom: 8,
                  color: colors.content.secondary,
                }}
              >
                Please resolve these errors to Publish the flow.
              </div>
              <>
                {selectedNode &&
                  isChildFlow &&
                  childFlowErrors?.errors.map((error: string) => {
                    return renderErrors(error, childFlowErrors?.flowDetails);
                  })}
                {!isChildFlow && errorState && errorState[currentFlow] && (
                  <>
                    {selectedNode ? (
                      <>
                        {Object.keys(errorState[currentFlow])
                          .filter((key) => {
                            return selectedNode ? selectedNode === key : key;
                          })
                          .map((key: any) => {
                            let data: { [x: string]: any; errors: any[] };
                            if (key === "child_reports") {
                              return Object.keys(
                                errorState[currentFlow]["child_reports"]
                              ).map((key: any) => {
                                data =
                                  errorState[currentFlow]["child_reports"][key];
                                const flowDetails = data["flow_details"];
                                return Object.keys(
                                  errorState[currentFlow]["child_reports"][key]
                                ).map((nodeId: any) => {
                                  return data[nodeId] &&
                                    data[nodeId].errors &&
                                    data[nodeId].errors.length ? (
                                    data[nodeId].errors.map((error: string) => {
                                      return renderErrors(
                                        error,
                                        flowDetails ? [flowDetails] : undefined
                                      );
                                    })
                                  ) : (
                                    <></>
                                  );
                                });
                              });
                            }
                            data = errorState[currentFlow][key];
                            return data && data.errors && data.errors.length ? (
                              data.errors.map((error: string) => {
                                return renderErrors(
                                  error,
                                  data?.error_flow_details
                                );
                              })
                            ) : (
                              <></>
                            );
                          })}
                      </>
                    ) : (
                      /* Club the errors together */
                      <>
                        <GlobalError
                          errors={
                            errorState[currentFlow]?.combined_errors ?? {}
                          }
                        />
                        {renderGlobalChildReport(
                          errorState[currentFlow].child_reports,
                          false
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </>
          )}

          {((!selectedNode && errorStateComplete.warningsCount > 0) ||
            (selectedNode && nodeWarningsCount > 0)) && (
            <>
              <div className="title-regular" style={{ marginTop: 16 }}>
                Warnings
              </div>
              <div
                className="body-secondary"
                style={{
                  marginTop: 4,
                  marginBottom: 8,
                  color: colors.content.secondary,
                }}
              >
                Flow might not work properly.
              </div>
              <>
                {selectedNode &&
                  isChildFlow &&
                  childFlowWarnings?.warnings.map((error: string) => {
                    return renderWarnings(
                      error,
                      childFlowWarnings?.flowDetails
                    );
                  })}
                {!isChildFlow && errorState && errorState[currentFlow] && (
                  <>
                    {selectedNode ? (
                      <>
                        {Object.keys(errorState[currentFlow])
                          .filter((key) => {
                            return selectedNode ? selectedNode === key : key;
                          })
                          .map((key: any) => {
                            let data: { [x: string]: any; errors: any[] };
                            if (key === "child_reports") {
                              return Object.keys(
                                errorState[currentFlow]["child_reports"]
                              ).map((key: any) => {
                                data =
                                  errorState[currentFlow]["child_reports"][key];
                                const flowDetails = data["flow_details"];
                                return Object.keys(
                                  errorState[currentFlow]["child_reports"][key]
                                ).map((nodeId: any) => {
                                  return data[nodeId] &&
                                    data[nodeId].warnings &&
                                    data[nodeId].warnings.length ? (
                                    data[nodeId].warnings.map(
                                      (warning: string) => {
                                        return renderWarnings(
                                          warning,
                                          flowDetails
                                            ? [flowDetails]
                                            : undefined
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  );
                                });
                              });
                            }
                            data = errorState[currentFlow][key];
                            return data &&
                              data.warnings &&
                              data.warnings.length ? (
                              data.warnings.map((error: string) => {
                                const showFlowDetails = error.startsWith(
                                  "Trigger is currently in use by other flow"
                                );
                                return renderWarnings(
                                  error,
                                  showFlowDetails
                                    ? data?.warning_flow_details
                                    : undefined
                                );
                              })
                            ) : (
                              <></>
                            );
                          })}
                      </>
                    ) : (
                      /* Club the warnings together */
                      <>
                        <GlobalWarning
                          warnings={
                            errorState[currentFlow]?.combined_warnings ?? {}
                          }
                        />
                        {renderGlobalChildReport(
                          errorState[currentFlow].child_reports,
                          true
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </>
          )}
        </div>
      </ErrorsModalStyle>
    </StyledModal>
  );
};

export default ErrorsModal;
